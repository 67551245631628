var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        title: _vm.title,
        visible: _vm.dialogVisible,
        width: "600px",
        "before-close": _vm.cancelHandle,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.formRule,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "所属客户" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-input", {
                        attrs: { value: _vm.form.customerName, disabled: true },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { prop: "name", label: "职级" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入职级", maxlength: "20" },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { prop: "salary", label: "薪酬区间" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "salary",
                          attrs: { maxlength: "3" },
                          model: {
                            value: _vm.form.minSalary,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "minSalary", _vm._n($$v))
                            },
                            expression: "form.minSalary",
                          },
                        },
                        [_c("template", { slot: "append" }, [_vm._v("K")])],
                        2
                      ),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 1, offset: 1 } }, [
                    _vm._v("—"),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 7, offset: 1 } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "salary",
                          attrs: { maxlength: "3" },
                          model: {
                            value: _vm.form.maxSalary,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "maxSalary", _vm._n($$v))
                            },
                            expression: "form.maxSalary",
                          },
                        },
                        [_c("template", { slot: "append" }, [_vm._v("K")])],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { prop: "discription", label: "相关补充" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "填写，如是否有股权激励等（200字以内）",
                          autosize: { maxRows: 4, minRows: 4 },
                          resize: "none",
                          maxlength: "200",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.form.discription,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "discription", $$v)
                          },
                          expression: "form.discription",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.cancelHandle } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.confirmHandle } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }