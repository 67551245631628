var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "custmers-rank" }, [
    _c(
      "div",
      { staticClass: "custmers-rank-box" },
      [
        _vm._m(0),
        _c(
          "el-row",
          [
            _c(
              "el-tabs",
              {
                staticClass: "customerRank",
                class: { normal: _vm.stage === 1 },
                attrs: { "tab-position": "left" },
              },
              [
                _c(
                  "el-tab-pane",
                  { attrs: { label: "popper-append-to-body", disabled: "" } },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "tab-list__btn",
                        attrs: { slot: "label" },
                        slot: "label",
                      },
                      [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              "popper-class": "popover-select",
                              placement: "bottom",
                              width: "290",
                              trigger: "click",
                              content: "",
                            },
                            on: { "after-leave": _vm.clearInput },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "chose-customer",
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [
                                _c(
                                  "i",
                                  {
                                    staticClass:
                                      "icon-plus primary el-icon--left",
                                  },
                                  [_vm._v("选择客户")]
                                ),
                              ]
                            ),
                            _vm._t("default", function () {
                              return [
                                _c("div", { staticClass: "el-input" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.search,
                                        expression: "search",
                                      },
                                    ],
                                    staticClass: "el-input__inner",
                                    attrs: {
                                      type: "text",
                                      autocomplete: "off",
                                      placeholder: "请输入搜索字段",
                                    },
                                    domProps: { value: _vm.search },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.search = $event.target.value
                                      },
                                    },
                                  }),
                                ]),
                                _c(
                                  "ul",
                                  _vm._l(_vm.customer, function (item) {
                                    return !item.isHasGrade &&
                                      item.customerName.indexOf(_vm.search) !==
                                        -1
                                      ? _c(
                                          "li",
                                          {
                                            key: item.customerId,
                                            staticClass: "text-ellipsis",
                                            attrs: { title: item.customerName },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addCustomer(item)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(item.customerName) +
                                                "\n                                        "
                                            ),
                                          ]
                                        )
                                      : _vm._e()
                                  }),
                                  0
                                ),
                              ]
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _vm.isDefault
                      ? _c("el-row", [
                          _c(
                            "div",
                            { staticClass: "default" },
                            [
                              _c("i", { staticClass: "table-center" }, [
                                _vm._v(
                                  "\n                                暂无职级数据\n                            "
                                ),
                              ]),
                              _c("span", { staticClass: "table-center" }, [
                                _vm._v(
                                  "\n                                请添加客户后，即可编辑该客户职级了哦～\n                            "
                                ),
                              ]),
                              _c(
                                "el-button",
                                {
                                  staticClass: "add-customer-btn table-center",
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.addCustomerBtn.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "i",
                                    { staticClass: "el-icon--left icon-plus" },
                                    [_vm._v("添加客户")]
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "table-center" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/images/customer/pic_emptydate.png"),
                                    alt: "",
                                  },
                                }),
                              ]),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._l(_vm.customerEditList, function (item, index) {
                  return item.isHasGrade
                    ? _c(
                        "el-tab-pane",
                        { key: "item_" + index },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "label" }, slot: "label" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "text-ellipsis",
                                  class: { active: item.isActive },
                                  attrs: { title: item.customerName },
                                  on: {
                                    click: function ($event) {
                                      return _vm.tabChange(item)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(item.customerName) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c("el-row", [
                            _c(
                              "div",
                              { staticClass: "right-wrap-item" },
                              [
                                _c("span", { staticClass: "item-title-h1" }, [
                                  _vm._v(_vm._s(item.customerName) + " （"),
                                  _c("span", { staticClass: "orange" }, [
                                    _vm._v(" " + _vm._s(_vm.total) + " "),
                                  ]),
                                  _vm._v("）"),
                                ]),
                                _vm.stage === 2
                                  ? _c(
                                      "el-button",
                                      {
                                        staticClass: "btn-newJobRank",
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addJobRank(0, item)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "i",
                                          {
                                            staticClass:
                                              "el-icon--left icon-plus",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    新增职级\n                                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-table",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.loading,
                                      expression: "loading",
                                    },
                                  ],
                                  staticStyle: {
                                    width: "100%",
                                    "min-height": "400px",
                                  },
                                  attrs: { data: _vm.tableData },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "name",
                                      label: "职级",
                                      width: "260",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  attrs: {
                                                    title: scope.row.name,
                                                  },
                                                },
                                                [_vm._v(_vm._s(scope.row.name))]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "salary",
                                      label: "薪酬区间",
                                      width: "240",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  attrs: {
                                                    title: scope.row.salary,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(scope.row.salary)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "discription",
                                      label: "相关补充",
                                      width: "220",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  attrs: {
                                                    title:
                                                      scope.row.discription,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row.discription
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "updater",
                                      label: "创建人/更新人",
                                      width: "250",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  attrs: {
                                                    title: scope.row.updater,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(scope.row.updater)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm.stage === 2
                                    ? _c("el-table-column", {
                                        attrs: { prop: "menu", label: "操作" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      staticClass:
                                                        "rank-operate-btn",
                                                      attrs: {
                                                        type: "text",
                                                        size: "medium",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.addJobRank(
                                                            1,
                                                            scope.row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("编辑")]
                                                  ),
                                                  _c(
                                                    "el-button",
                                                    {
                                                      staticClass:
                                                        "rank-operate-btn",
                                                      attrs: {
                                                        type: "text",
                                                        size: "medium",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.delJobRank(
                                                            scope.row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("删除")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.total && _vm.total > _vm.page.take
                            ? _c(
                                "el-pagination",
                                {
                                  staticClass: "pagination-common",
                                  attrs: {
                                    "current-page": _vm.page.current,
                                    layout: "total, prev, pager, next, slot",
                                    total: _vm.total,
                                  },
                                  on: {
                                    "current-change": _vm.handleCurrentChange,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "pagination-text" },
                                    [
                                      _c(
                                        "span",
                                        [
                                          _vm._v("前往"),
                                          _c("el-input", {
                                            nativeOn: {
                                              keyup: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                return _vm.handlePagerJump.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.pagerJump,
                                              callback: function ($$v) {
                                                _vm.pagerJump = $$v
                                              },
                                              expression: "pagerJump",
                                            },
                                          }),
                                          _vm._v("页"),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "span",
                                        { on: { click: _vm.handlePagerJump } },
                                        [_vm._v("跳转")]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e()
                }),
              ],
              2
            ),
          ],
          1
        ),
        _c("addJobRankDialog", {
          ref: "addJobRankDialog",
          attrs: { title: _vm.title },
          on: { successFn: _vm.addJobRankSuccessded },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "lbd-sign" }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "right-wrap-item" }, [
      _c("span", { staticClass: "item-title-h2" }, [_vm._v("全部客户职级")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }