<template>
    <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="600px"
    v-loading="loading"
    :before-close="cancelHandle"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    >
        <el-form :model="form" :rules="formRule" ref="form" label-width="100px">
            <el-row>
                <el-form-item label="所属客户">
                    <el-col :span="24">
                        <el-input :value="form.customerName"
                                  :disabled="true"></el-input>
                    </el-col>
                </el-form-item>
            </el-row>

            <el-row>
                <el-form-item prop="name" label="职级">
                    <el-col :span="24">
                        <el-input v-model="form.name" placeholder="请输入职级" maxlength="20"></el-input>
                    </el-col>
                </el-form-item>
            </el-row>

            <el-row>
                <el-form-item prop="salary" label="薪酬区间">
                    <el-col :span="7">
                        <el-input
                            class="salary"
                            maxlength="3"
                            v-model.number="form.minSalary">
                            <template slot="append">K</template>
                        </el-input>
                    </el-col>
                    <el-col :span="1" :offset="1">—</el-col>
                    <el-col :span="7" :offset="1">
                        <el-input
                            class="salary"
                            v-model.number="form.maxSalary"
                            maxlength="3">
                            <template slot="append">K</template>
                        </el-input>
                    </el-col>
                </el-form-item>
            </el-row>

            <el-row>
                <el-form-item prop="discription" label="相关补充">
                    <el-col :span="24">
                        <el-input v-model="form.discription"
                                  type="textarea"
                                  placeholder="填写，如是否有股权激励等（200字以内）"
                                  :autosize="{ maxRows: 4,minRows: 4}"
                                  resize="none"
                                  maxlength="200"
                                  show-word-limit></el-input>
                    </el-col>
                </el-form-item>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="cancelHandle">取消</el-button>
            <el-button type="primary" @click="confirmHandle">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import customerService from '#/js/service/customerService.js';

    export default {
        name: "addJobRank-dialog",
        components: {
        },
        data() {
            return {
                dialogVisible:false,
                loading:false,
                form: {
                    id: '',
                    customerName: '',
                    name: '',
                    salary: '',
                    discription: '',
                    maxSalary: '',
                    minSalary: ''
                },
                formRule: {
                    name: [
                        {
                            required: true,
                            validator: this.checkName,
                            trigger: 'blur',
                        }
                    ],
                    salary: [
                        {
                            required: true,
                            validator: this.checkSalary,
                            trigger: 'blur',
                        }
                    ]
                }
            }
        },
        props: {
            title: {
                default: '新增职级',
                type: String
            }
        },
        methods: {
            /**
             * 调用dialog
             */
            showHandle(data = {customerId, customerName, name, maxSalary, minSalary, discription} = {}) {
                this.form = {
                    name: data.name || '',
                    maxSalary: data.maxSalary || '',
                    minSalary: data.minSalary || '',
                    discription: data.discription || '',
                    customerId: data.customerId || '',
                    id: data.id || '',
                    customerName: data.customerName || ''
                };
                this.customer = data;
                this.dialogVisible = true;
            },
            confirmHandle() {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        customerService
                            .gradeCreateOrUpdate(
                                this.form,
                                this.$store.state.verificationToken.verificationToken
                            ).then(res => {
                                this.$emit('successFn', res);
                                this.dialogVisible = false;
                            }).finally(() => {
                                this.loading = false;
                            })
                    } else {

                    }
                })
            },
            cancelHandle() {
                this.dialogVisible = false;
            },
            checkSalary (rule,value,callback){
                let numberReg = /[^0-9]+/ig;
                if(this.form.maxSalary && this.form.minSalary){
                    this.form.salary = `${this.form.maxSalary} - ${this.form.minSalary}`
                } else {
                    return callback(new Error('请输入薪资区间'))
                }

                if(numberReg.test(this.form.minSalary)){
                    return callback(new Error('月薪的取值范围是1-999'))
                }

                if(numberReg.test(this.form.maxSalary)){
                    return callback(new Error('月薪的取值范围是1-999'))
                }

                if(this.form.maxSalary > 999 || this.form.minSalary > 999 || this.form.minSalary < 0 || this.form.minSalary > this.form.maxSalary){
                    callback(new Error('最低薪资应小于最高薪资'))
                }
                callback();
            },
            checkName(rule,value,callback){
                if(!value){
                    return callback(new Error('请输入职级！'))
                }

                // if(/[^\x00-\xff]/ig.test(value)){
                //     return callback(new Error('请不要输入中文字符！'))
                // }

                callback();
            }
        }
    }
</script>

<style lang="scss" scoped>
    .el-form /deep/ {
        .el-form-item {
            margin-bottom: 0;

            .el-form-item__label {
                margin-bottom: 0;
                padding: 13px 30px 10px 0;
                line-height: 1;
                text-align: justify;

                &:before {
                    position: absolute;
                    left: -10px;
                }

                &:after {
                    content: '';
                    display: inline-block;
                    width: 100%;
                }
            }

            .el-input__inner:focus{
                border-color: $primary;
            }

            &.is-error {
                .el-input__inner{
                    & + div{
                        border-color: #F56C6C;
                    }
                }

                .el-form-item__content {
                    margin-bottom: 15px;
                }
            }

            &.is-success{
                .el-input__inner{
                    border-color: #DCDFE6;

                    &+div{
                        border-color: #DCDFE6;
                    }
                }
            }

            .el-form-item__error{
                margin-top: -8px;
            }

            .el-input__count{
                bottom: 8px;
                line-height: 1;
            }
        }

        .salary{
            .el-input__inner{
                border-right: none;

                &:focus,&:focus+div{
                    border-color: $primary;
                }
            }
            .el-input-group__append{
                padding: 0 8px;
                background-color: transparent;
                border-left: none;
                color: #999;
            }
        }
    }
</style>
